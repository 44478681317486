import React from "react";
import Modal from "../../modal";
import ButtonWithAction from "../../../atoms/button-with-action";
import Text from "../../../atoms/text";
import { AutopayOnIcon } from "../../../atoms/icons";
import { Box, Cluster } from "../../../atoms/layouts";
import { SEA_GREEN } from "../../../../constants/colors";
import { ACH_METHOD, CC_METHOD } from "../../../../constants/general";
import { titleCaseString, noop } from "../../../../util/general";
import { FONT_WEIGHT_REGULAR } from "../../../../constants/style_constants";
import Paragraph from "../../../atoms/paragraph/index";

const AutopayModalModule = ({
  autoPayActive,
  autoPaySchedule,
  paymentPlanSchedule,
  toggleModal,
  modalOpen,
  deactivatePaymentSchedule,
  navigateToSettings,
  controlType = "tertiary",
  isMobile,
  isPaymentPlan,
  nextAutopayDate,
  dueDate,
  inactive,
  description,
  subDescription,
  allowedPaymentInstruments,
  disableActions = false,
  action,
  onClick,
  onKeyPress
}) => {
  const generateMethodNeededText = (planText, allowedPaymentInstruments) => {
    const allowsCard = allowedPaymentInstruments?.includes(CC_METHOD);
    const allowsACH = allowedPaymentInstruments?.includes(ACH_METHOD);
    const methodRequired =
      allowsCard && !allowsACH
        ? "debit or credit card payment method"
        : !allowsCard && allowsACH
        ? "checking account payment method"
        : "payment method";

    return `To set up ${planText} you must have a saved ${methodRequired} and address. Do you want to save these now?`;
  };
  const plan = isPaymentPlan ? "your payment plan" : "autopay";
  const shortPlan = isPaymentPlan ? "Payment Plan" : "Autopay";
  const deactivateText = `Stop ${shortPlan.toLowerCase()} for ${titleCaseString(
    description
  )}: ${titleCaseString(subDescription)}`;
  const activateText = `Set up ${shortPlan.toLowerCase()} for ${titleCaseString(
    description
  )}: ${titleCaseString(subDescription)}`;
  const nextDate = dueDate || nextAutopayDate;
  const modalExtraProps = {
    modalHeaderText: autoPayActive ? deactivateText : activateText,
    modalBodyText: autoPayActive ? (
      <>
        <Paragraph>
          Are you sure you want to stop {plan}?
          {!inactive && nextDate && nextDate !== "On"
            ? " Your next payment will be due on " + nextDate + "."
            : ""}
        </Paragraph>
        {!isPaymentPlan && (
          <>
            <br />
            <Paragraph>
              If your next autopayment is scheduled to run today, you may be
              billed today and autopay will then stop for the next billing
              cycle.
            </Paragraph>
          </>
        )}
      </>
    ) : (
      generateMethodNeededText(plan, allowedPaymentInstruments)
    ),
    continueButtonText: autoPayActive ? `Stop ${shortPlan}` : "Add",
    useDangerButton: autoPayActive,
    continueAction: autoPayActive
      ? () => {
          deactivatePaymentSchedule(
            isPaymentPlan ? paymentPlanSchedule : autoPaySchedule,
            isPaymentPlan
          );
          toggleModal(false);
        }
      : navigateToSettings
  };
  const modalLinkHoverFocus = `
    outline: none;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #317D4F;
  `;

  const hoverStyles = "text-decoration: underline;";
  const activeStyles = "text-decoration: underline;";

  const renderAutoPayControl = () => {
    switch (controlType) {
      case "secondary": {
        return (
          <ButtonWithAction
            text={
              autoPayActive ? `Turn off ${shortPlan}` : `Set Up ${shortPlan}`
            }
            variant="secondary"
            action={action || (() => toggleModal(true))}
            dataQa="Turn off Autopay"
            extraStyles={
              isMobile
                ? `flex-grow: 1; width: 100%; margin: 0;`
                : `flex-grow: 1; min-width: 165px;`
            }
            disabled={disableActions}
          />
        );
      }
      case "tertiary": {
        return (
          <ButtonWithAction
            text={autoPayActive ? `Manage ${shortPlan}` : `Set Up ${shortPlan}`}
            variant="tertiary"
            action={action || (() => toggleModal(true))}
            dataQa="Manage Autopay"
            extraStyles={isMobile && `flex-grow: 1; width: 100%;`}
            disabled={disableActions}
          />
        );
      }
      case "link": {
        return (
          <Box
            padding="0"
            onClick={
              onClick ||
              (() => {
                toggleModal(true);
              })
            }
            hoverStyles={hoverStyles}
            activeStyles={activeStyles}
            extraStyles={"cursor: pointer;"}
          >
            <Cluster
              justify={isMobile ? "flex-start" : "flex-end"}
              align="center"
            >
              <AutopayOnIcon />
              <Text
                variant="pS"
                onClick={onClick || (() => toggleModal(true))}
                onKeyPress={
                  onKeyPress ||
                  (e => {
                    e.key === "Enter" && toggleModal(true);
                  })
                }
                tabIndex="0"
                dataQa={`${shortPlan} On`}
                color={SEA_GREEN}
                weight={FONT_WEIGHT_REGULAR}
                hoverStyles={modalLinkHoverFocus}
                extraStyles={`padding-left: 0.25rem;`}
                disabled={disableActions}
              >
                {`${shortPlan} ${nextAutopayDate}`}
              </Text>
            </Cluster>
          </Box>
        );
      }
    }
  };
  return (
    <Modal
      showModal={() => toggleModal(true)}
      hideModal={() => toggleModal(false)}
      modalOpen={modalOpen}
      {...modalExtraProps}
    >
      {renderAutoPayControl()}
    </Modal>
  );
};

export default AutopayModalModule;
