import { createSelector } from "reselect";
import { getDefaultConfig, getPaymentConfigs } from "./ShoppingCart.selectors";

export const getCarts = state => state.cart.carts;

export const getAllCarts = createSelector([getCarts], carts =>
  Object.entries(carts).map(([_cartId, cart]) => cart)
);

export const getMultiCartItemsCount = createSelector([getAllCarts], carts =>
  carts.reduce((acc, cart) => acc + cart.items?.length, 0)
);

export const getMultiCartTotal = createSelector([getAllCarts], carts =>
  carts.reduce((acc, cart) => acc + cart.total, 0)
);

export const getCart = createSelector(
  [getCarts, (_state, cartId) => cartId],
  (carts, cartId) => carts[cartId]
);

export const getCartPaymentConfig = createSelector(
  [getCart],
  cart => cart?.config?.payment
);

export const getSubClientSlug = createSelector(
  [getCart],
  cart => cart?.config?.subClientSlug
);

export const getCartAccountNumber = createSelector(
  [getCart],
  cart => cart.config?.accountId
);

export const getSubClientPaymentConfig = createSelector(
  [getSubClientSlug, getPaymentConfigs, getDefaultConfig],
  (subClientSlug, paymentConfigs, defaultConfig) =>
    paymentConfigs?.[subClientSlug] ?? defaultConfig
);

export const getCartAllowedPaymentMethods = createSelector([getCart], cart => {
  const paymentTypes = {
    BANK_ACCOUNT: "bank",
    CREDIT_CARD: "card",
    CASH: "cash"
  };
  return cart.config?.allowedPaymentInstruments?.map(
    method => paymentTypes[method]
  );
});

export const getPaymentConfigOptions = createSelector(
  [getCartPaymentConfig],
  config => config?.options
);

export const getPaymentConfigCustomAttrs = createSelector(
  [getCartPaymentConfig],
  config => config?.customAttributes
);

export const getPaymentConfigServiceName = createSelector(
  [getCartPaymentConfig],
  config => config?.serviceName
);

export const getVisitIdFromCart = createSelector(
  [getCart],
  cart => cart?.config?.visitId
);

export const getCartLineItems = createSelector([getCart], cart => cart.items);

export const getAccountLookupConfigKey = createSelector(
  [getCart],
  cart => cart?.config?.accountLookupConfigKey
);

export const getPaymentAgencyDisplayName = paymentConfig =>
  paymentConfig?.options?.agencyDisplayName;
