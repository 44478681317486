import React, { useEffect, useContext, Fragment } from "react";
import * as L from "partial.lenses";
import {
  useRoutes,
  useParams,
  useNavigate,
  useLocation
} from "react-router-dom";
import { includes, pipe } from "ramda";
import { ThemeContext } from "styled-components";
import {
  Paragraph,
  Box,
  Center,
  TabSidebar,
  SidebarStackContent,
  SettingsIconSmall,
  FindIconSmall,
  AccountsIconSmall,
  PropertiesIconSmall,
  WalletIconSmall,
  HistoryIconSmall,
  withWindowSize,
  Loading,
  util
} from "@thecb/components";
import { fallbackValues } from "./Profile.theme";
import { routes } from "./routes";
import {
  buildRoutes,
  configureRoutes,
  getProfileChildRoutes,
  filterRoutesByCustomerManagement,
  filterRoutesByFindableAccounts,
  isInCustomerManagement
} from "/util/router-utils";
import { FILTERED_FINDABLE_ACCOUNTS_RESOURCE } from "../../pages/user-profile/Profile.state";

const SubHeader = ({ settings }) => {
  const themeContext = useContext(ThemeContext);
  const themeValues = util.theme.createThemeValues(
    themeContext,
    fallbackValues,
    "ProfileTab"
  );

  return (
    <Box
      background={themeValues.subheader}
      width="100%"
      extraStyles={`box-shadow: inset 0px -1px 0px 0px rgb(202, 206, 216),
        0px 0px 8px 0px rgb(202, 206, 216);`}
    >
      <Center maxWidth="76.5rem">
        <Box padding="0">
          <Paragraph
            variant="pL"
            weight="600"
            color={themeValues.subheaderNameColor}
            margin="0"
          >
            Hello {settings.resources.value.firstName}
          </Paragraph>
          <Paragraph
            variant="pS"
            color={themeValues.subheaderEmailColor}
            margin="0"
            extraStyles={`font-style: italic;`}
          >
            {settings.resources.value.email}
          </Paragraph>
        </Box>
      </Center>
    </Box>
  );
};

const Profile = ({
  onLogout,
  settings,
  resourcesActions,
  forms,
  actions,
  alertBars,
  childRoutes,
  isLoggedIn,
  isAuthenticatedAdmin,
  profileRoutes,
  canAddObligation,
  canRemoveObligation,
  welcomeCards,
  paymentConfigs,
  admin = false,
  hasPaymentFlag,
  walletEnabled,
  handleFocusErrors,
  obligationsConfig,
  deniedCardsForClient,
  cartEnabled,
  carts,
  accountLoadingExperienceEnabled
}) => {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  const { profileId } = params;
  useEffect(() => {
    if (!!profileId) {
      resourcesActions.startCustomerManagement(profileId);
    }
  }, []);

  useEffect(() => {
    if (admin && !isAuthenticatedAdmin) {
      navigate("/admin/login");
    }
    if (!admin && !isLoggedIn) {
      navigate("/login");
    }
  }, []);

  const themeContext = useContext(ThemeContext);
  const themeValues = util.theme.createThemeValues(
    themeContext,
    fallbackValues,
    "ProfileTab"
  );
  const { isMobile } = themeContext;
  const settingsFetched = settings.resources.isSuccess;

  const obligationsResource = settings?.resources?.value?.obligations;
  const obligationsFetched =
    (obligationsResource?.obligationsLoaded ?? false) ||
    (obligationsResource?.isSuccess ?? false) ||
    (obligationsResource?.isFailure ?? false);

  const findableAccounts = L.get(
    L.prop(FILTERED_FINDABLE_ACCOUNTS_RESOURCE),
    settings.resources.value
  );

  const profileRouting = pipe(
    getProfileChildRoutes,
    filterRoutesByCustomerManagement(isInCustomerManagement),
    configureRoutes,
    filterRoutesByFindableAccounts(findableAccounts)
  )(profileRoutes);

  const isRouteActive = route => includes(route, location.pathname);
  const getIcon = route => {
    switch (route) {
      case "find":
        return (
          <FindIconSmall
            iconIndex={0}
            variant={isRouteActive(route) ? "primary" : "secondary"}
          />
        );
      case "accounts":
        return (
          <AccountsIconSmall
            variant={isRouteActive(route) ? "primary" : "secondary"}
          />
        );
      case "settings":
        return (
          <SettingsIconSmall
            variant={isRouteActive(route) ? "primary" : "secondary"}
          />
        );
      case "properties":
        return (
          <PropertiesIconSmall
            variant={isRouteActive(route) ? "primary" : "secondary"}
          />
        );
      case "wallet":
        return (
          <WalletIconSmall
            variant={isRouteActive(route) ? "primary" : "secondary"}
          />
        );
      case "history":
        return (
          <HistoryIconSmall
            variant={isRouteActive(route) ? "primary" : "secondary"}
          />
        );
      default:
        return <Fragment />;
    }
  };
  useEffect(() => {
    if (!settingsFetched) {
      resourcesActions.fetchResources();
    }
  }, [settingsFetched]);
  const obligationAssociations = settings?.resources?.value?.obligations ?? {};
  const childProps = {
    requests: settings.requests,
    forms,
    alertBars,
    actions,
    resources: settings.resources.value,
    autoPayData: settings.automaticPaymentData,
    paymentPlanData: settings.paymentPlanData,
    resourcesActions,
    onLogout,
    selectedPaymentForm: settings.selectedPaymentForm,
    selectedAutopaymentMethodId:
      settings.automaticPaymentData.selectedAutopaymentMethodId,
    termsAndConditionsAgreedTo:
      settings.automaticPaymentData.termsAndConditionsAgreedTo,
    themeValues,
    detailedObligation: settings.detailedObligation,
    canAddObligation: canAddObligation,
    canRemoveObligation: canRemoveObligation,
    profileRouting: profileRouting,
    paymentConfigs,
    customerManagement: profileId,
    hasPaymentFlag,
    welcomeCards,
    walletEnabled,
    obligationAssociations,
    handleFocusErrors,
    obligationsConfig,
    deniedCardsForClient: deniedCardsForClient,
    cartEnabled,
    carts,
    accountLoadingExperienceEnabled
  };
  const subRoutes = useRoutes([
    ...buildRoutes(childRoutes, routes, childProps)
  ]);

  const handleProfilePadding = () =>
    isMobile && location.pathname === "/welcome"
      ? "0px"
      : isMobile
      ? "0 16px"
      : "0";
  return (
    <SidebarStackContent
      subHeader={settingsFetched && <SubHeader settings={settings} />}
      sidebarContent={
        settingsFetched &&
        obligationsFetched && (
          <TabSidebar
            links={profileRouting.map(route => ({
              route: admin
                ? `/admin/${profileId}/profile/${route}`
                : `/profile/${route}`,
              text: route,
              active: isRouteActive(route),
              icon: getIcon(route)
            }))}
            isMobile={isMobile}
            key="tab-sidebar"
          />
        )
      }
      sidebarWrapperPadding={isMobile ? "0 0 32px 0" : "32px 16px 48px 12px"}
      sidebarWrapperMaxWidth="78.5rem"
      sidebarContentGap={isMobile ? "24px" : "4.75rem"}
      sidebarTargetWidth="14rem"
      mainContentMinWidth={isMobile && "100%"}
      mainContent={
        settingsFetched && obligationsFetched ? (
          <Box key="subroutes-container" padding={handleProfilePadding}>
            {subRoutes}
          </Box>
        ) : (
          <Loading />
        )
      }
    />
  );
};

export default withWindowSize(Profile);
