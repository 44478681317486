import React from "react";
import { combineEpics } from "redux-observable";
import { connect } from "react-redux";

import Profile from "./Profile";
import * as state from "./Profile.state";
import * as selectors from "./Profile.selectors";
import createState from "./Profile.state";

import FetchProfileEpic, {
  associateObligationEpic,
  createPaymentFromProfileEpic,
  deactivatePaymentInstrumentEpic,
  refreshObligationsEpic,
  fetchObligationEpic,
  fetchObligationsEpic,
  fetchObligationsSuccessEpic,
  navigateToDetailedObligation,
  checkPaymentExpirationEpic,
  storeDetailedObligation,
  fetchTransactionHistoryEpic,
  fetchTransactionEpic,
  fetchAccountBillURLEpic
} from "./Profile.epics";
import ChangePasswordEpic from "./sub-pages/profile-change-password/ChangePassword.epic";
import EditNameEpic from "./sub-pages/profile-edit-name/EditName.epic";
import createCreditCardEpic from "./sub-pages/profile-add-payment/AddCreditCard.epic";
import createBankAccountEpic from "./sub-pages/profile-add-payment/AddAch.epic";
import addressEpics from "./sub-pages/profile-add-address/AddAddress.epic";
import emailEpics from "./sub-pages/profile-add-email/AddEmail.epic";
import phoneEpics from "./sub-pages/profile-add-phone/AddPhone.epic";
import createPaymentScheduleEpic from "./sub-pages/profile-set-up-autopay/SetUpAutopay.epic";
import deactivateScheduleEpic from "./sub-pages/profile-account-details/DeactivateAutopay.epic";
import deleteObligationAssocEpic from "./sub-pages/profile-account-details/DeleteObligationAssoc.epic";

const ConnectedProfile = ({ mapStateToProps, mapDispatchToProps }) =>
  connect(mapStateToProps, mapDispatchToProps)(props => <Profile {...props} />);

const epic = (key, selectorsConfig) =>
  combineEpics(
    FetchProfileEpic(selectorsConfig),
    ChangePasswordEpic(key, selectorsConfig),
    EditNameEpic(key, selectorsConfig),
    addressEpics(key, selectorsConfig),
    emailEpics(key, selectorsConfig),
    phoneEpics(key, selectorsConfig),
    createCreditCardEpic(key, selectorsConfig),
    createBankAccountEpic(key, selectorsConfig),
    associateObligationEpic(key, selectorsConfig),
    createPaymentFromProfileEpic(selectorsConfig),
    createPaymentScheduleEpic(key, selectorsConfig),
    deactivateScheduleEpic(key, selectorsConfig),
    deactivatePaymentInstrumentEpic(selectorsConfig),
    navigateToDetailedObligation(key),
    storeDetailedObligation,
    deleteObligationAssocEpic(key, selectorsConfig),
    refreshObligationsEpic(selectorsConfig),
    fetchObligationsEpic(selectorsConfig),
    fetchObligationsSuccessEpic(selectorsConfig),
    fetchObligationEpic(selectorsConfig),
    checkPaymentExpirationEpic,
    fetchTransactionHistoryEpic(selectorsConfig),
    fetchTransactionEpic(selectorsConfig),
    fetchAccountBillURLEpic(selectorsConfig)
  );

const createProfileState = (key, selectorsConfig) => ({
  epic: epic(key, selectorsConfig),
  reducer: clearStateOn => ({
    [key]: createState(key, selectorsConfig).reducer(clearStateOn)
  }),
  mapStateToProps: createState(key, selectorsConfig).mapStateToProps,
  mapDispatchToProps: createState(key, selectorsConfig).mapDispatchToProps
});

export { createProfileState, state, selectors };

export default ConnectedProfile;
