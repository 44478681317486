import React, { useContext, useEffect } from "react";
import * as R from "ramda";
import { push } from "connected-react-router";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeContext } from "styled-components";
import {
  Placeholder,
  Box,
  Center,
  Stack,
  Obligation,
  Loading,
  Timeout,
  Title,
  CenterStack,
  Heading,
  Paragraph,
  Detail,
  withWindowSize,
  constants,
  NotFoundIcon
} from "@thecb/components";
import AlertBar from "/components/alert-bar";
import { isAutoPayEnabled, retrieveAutoPayData } from "/util/general";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../../../constants/style_constants";
import {
  checkIsInCart,
  createCartConfig,
  createLineItems,
  getNextAutopayDate as getDueDate
} from "../../Profile.selectors";
import { isInCustomerManagement } from "../../../../../../util/router-utils";

const ProfileAccounts = ({
  resourcesActions,
  alertBars,
  actions,
  autoPayData,
  obligationAssociations,
  canAddObligation,
  resources,
  onLogout,
  cartEnabled,
  paymentConfigs,
  carts,
  accountLoadingExperienceEnabled
}) => {
  let navigate = useNavigate();
  const {
    createPaymentFromProfile,
    setDetailedObligation,
    deactivatePaymentSchedule,
    navigateToDetailedObligation,
    fetchObligations,
    deleteObligationAssoc,
    configureMultiCart,
    addToMultiCart,
    openCartSlider
  } = resourcesActions;
  const oblActions = {
    createPaymentFromProfile,
    setDetailedObligation,
    navigateToDetailedObligation,
    deleteObligationAssoc,
    configureMultiCart,
    addToMultiCart,
    openCartSlider
  };
  const { isMobile } = useContext(ThemeContext);
  const {
    achAccounts,
    addresses,
    creditCards,
    obligations,
    schedules
  } = resources;

  const getNextAutopayDate = getDueDate(schedules);
  const autoPayAvailable = R.and(
    R.or(R.not(R.isEmpty(achAccounts)), R.not(R.isEmpty(creditCards))),
    R.not(R.isEmpty(addresses))
  );
  const obligationsFetched =
    (obligations?.isSuccess ?? false) || (obligations?.isFailure ?? false);
  useEffect(() => {
    if (!obligationsFetched) {
      fetchObligations();
    }
  }, [obligationsFetched]);

  const activeAccounts = Object.entries(
    obligationAssociations?.accounts?.active ?? {}
  ).map(([activeGroupName, associations]) => {
    const accountObligations = associations.map(association => {
      const lineItems = createLineItems(association);
      const cartConfig = createCartConfig(
        association,
        lineItems,
        paymentConfigs
      );
      const isInCart = checkIsInCart(carts, association);

      if (association.config.collection) {
        return (
          <Obligation
            key={`${activeGroupName}-${association.id}-collection`}
            config={association.config}
            obligations={association.obligations}
            actions={oblActions}
            autoPayEnabled={isAutoPayEnabled(autoPayData, association.id)}
            autoPayAvailable={autoPayAvailable}
            autoPaySchedule={
              retrieveAutoPayData(autoPayData, association.id).id
            }
            handleAutopayAction={() => navigate("set-up-autopay")}
            navigateToSettings={() => navigate("/profile/wallet")}
            deactivatePaymentSchedule={deactivatePaymentSchedule}
            isMobile={isMobile}
            nextAutopayDate={getNextAutopayDate(association.id)}
            obligationAssocID={association.id}
            dueDate={association.dueDate}
            agencyName={activeGroupName}
            inactive={!!association.error}
            inactiveLookupTitle={
              association?.config?.inactiveLookupTitle ?? activeGroupName
            }
            inactiveLookupInput={association?.config?.inactiveLookupInput}
            inactiveLookupValue={
              association?.config?.inactiveLookupValue ?? association.account
            }
            inactiveCanNavigateToAccountDetails={
              accountLoadingExperienceEnabled
            }
            isInCustomerManagement={isInCustomerManagement}
            cartEnabled={cartEnabled}
            cartConfig={cartConfig}
            isInCart={isInCart}
            isLoading={association.isLoading}
            showRemoveButton={!accountLoadingExperienceEnabled}
          />
        );
      }
      return association.obligations?.map(obl => (
        <Obligation
          key={`${activeGroupName}-${association.id}-${obl.id}`}
          config={association.config}
          obligations={[obl]}
          actions={oblActions}
          autoPayEnabled={isAutoPayEnabled(autoPayData, association.id)}
          autoPayAvailable={autoPayAvailable}
          autoPaySchedule={retrieveAutoPayData(autoPayData, association.id).id}
          handleAutopayAction={() => navigate("set-up-autopay")}
          navigateToSettings={() => navigate("/profile/wallet")}
          deactivatePaymentSchedule={deactivatePaymentSchedule}
          isMobile={isMobile}
          nextAutopayDate={getNextAutopayDate(association.id)}
          obligationAssocID={association.id}
          dueDate={association.dueDate}
          agencyName={activeGroupName}
          inactive={!!association.error}
          inactiveLookupTitle={
            association?.config?.inactiveLookupTitle ?? activeGroupName
          }
          inactiveLookupInput={association?.config?.inactiveLookupInput}
          inactiveLookupValue={
            association?.config?.inactiveLookupValue ?? association.account
          }
          inactiveCanNavigateToAccountDetails={accountLoadingExperienceEnabled}
          isInCustomerManagement={isInCustomerManagement}
          cartEnabled={cartEnabled}
          cartConfig={cartConfig}
          isInCart={isInCart(association)}
          isLoading={association.isLoading}
          showRemoveButton={!accountLoadingExperienceEnabled}
        />
      ));
    });
    return (
      <Stack childGap="0.5rem" key={`active-accounts-${activeGroupName}`}>
        <Box padding="0">
          <Detail as="h3" variant="regular">
            {activeGroupName}
          </Detail>
        </Box>
        <Box padding="0">
          <Stack childGap="24px">
            {accountObligations}
            {/* Hide ability to add new account if in customer management */}
            {canAddObligation && (
              <Placeholder
                isLink
                isDisabled={isInCustomerManagement}
                text="Add more accounts"
                destination="/profile/find"
                dataQa="add account"
              />
            )}
          </Stack>
        </Box>
      </Stack>
    );
  });
  const inactiveAccounts = Object.entries(
    obligationAssociations?.accounts?.inactive ?? {}
  ).map(([inactiveGroupName, associations]) => {
    const accountObligations = associations.map(association => {
      if (association.config.collection) {
        return (
          <Obligation
            key={`${inactiveGroupName}-${association.id}-collection`}
            config={association.config}
            obligations={association.obligations}
            actions={oblActions}
            autoPayEnabled={isAutoPayEnabled(autoPayData, association.id)}
            autoPayAvailable={autoPayAvailable}
            autoPaySchedule={
              retrieveAutoPayData(autoPayData, association.id).id
            }
            handleAutopayAction={() => navigate("set-up-autopay")}
            navigateToSettings={() => navigate("/profile/wallet")}
            deactivatePaymentSchedule={deactivatePaymentSchedule}
            isMobile={isMobile}
            nextAutopayDate={getNextAutopayDate(association.id)}
            obligationAssocID={association.id}
            dueDate={association.dueDate}
            agencyName={inactiveGroupName}
            inactive
            inactiveLookupTitle={
              association?.config?.inactiveLookupTitle ?? inactiveGroupName
            }
            inactiveLookupInput={association?.config?.inactiveLookupInput}
            inactiveLookupValue={
              association?.config?.inactiveLookupValue ?? association.account
            }
            inactiveCanNavigateToAccountDetails={
              accountLoadingExperienceEnabled
            }
            isInCustomerManagement={isInCustomerManagement}
            isLoading={association.isLoading}
            showRemoveButton={!accountLoadingExperienceEnabled}
          />
        );
      }
      return association.obligations.map(obl => (
        <Obligation
          key={`${inactiveGroupName}-${association.id}-${obl.id}`}
          config={association.config}
          obligations={[obl]}
          actions={oblActions}
          autoPayEnabled={isAutoPayEnabled(autoPayData, association.id)}
          autoPayAvailable={autoPayAvailable}
          autoPaySchedule={retrieveAutoPayData(autoPayData, association.id).id}
          handleAutopayAction={() => navigate("set-up-autopay")}
          navigateToSettings={() => navigate("/profile/wallet")}
          deactivatePaymentSchedule={deactivatePaymentSchedule}
          isMobile={isMobile}
          nextAutopayDate={getNextAutopayDate(association.id)}
          obligationAssocID={association.id}
          dueDate={association.dueDate}
          agencyName={inactiveGroupName}
          inactive
          inactiveLookupTitle={
            association?.config?.inactiveLookupTitle ?? inactiveGroupName
          }
          inactiveLookupInput={association?.config?.inactiveLookupInput}
          inactiveLookupValue={
            association?.config?.inactiveLookupValue ?? association.account
          }
          inactiveCanNavigateToAccountDetails={accountLoadingExperienceEnabled}
          isInCustomerManagement={isInCustomerManagement}
          isLoading={obl.isLoading}
          showRemoveButton={!accountLoadingExperienceEnabled}
        />
      ));
    });
    return (
      <Stack childGap="0.5rem" key={`inactive-accounts-${inactiveGroupName}`}>
        <Box padding="0">
          <Detail as="h3" variant="regular">
            {inactiveGroupName}
          </Detail>
        </Box>
        <Box padding="0">
          <Stack childGap="1.5rem">{accountObligations}</Stack>
        </Box>
      </Stack>
    );
  });

  const { accountsAlertBar: alertBar } = alertBars;
  const {
    alertBars: { accountsAlertBar: alertBarActions }
  } = actions;

  const getStatus = obligations =>
    obligations.isLoading
      ? "loading"
      : obligations.isSuccess
      ? "success"
      : obligations.isFailure && obligations.error === "TIMEOUT"
      ? "timeout"
      : "not found";

  const renderObligations = obligations => {
    const status = getStatus(obligations);
    switch (status) {
      case "loading":
        return <Loading />;
      case "timeout":
        return <Timeout onLogout={onLogout} />;
      case "success":
        return (
          <Box
            padding="0"
            as="section"
            aria-labelledby="accounts-section-heading"
          >
            {/*Box below with srOnly is to provide screen readers with an h1 - invisible to sighted users */}
            <Box padding="0" srOnly>
              <Title as="h1" variant="large" id="accounts-section-heading">
                Accounts
              </Title>
            </Box>
            {R.isEmpty(activeAccounts) && (
              <Box
                background={constants.colors.WHITE}
                extraStyles={`box-shadow: 0px 2px 14px 0px ${constants.colors.ATHENS_GREY},
      0px 3px 8px 0px ${constants.colors.GHOST_GREY}; margin-bottom: 16px;`}
                key="placeholder-box"
              >
                <Placeholder
                  isLink
                  variant="large"
                  isDisabled={isInCustomerManagement}
                  destination="/profile/find"
                  text="Find an Account"
                  largeIcon="accounts"
                  key="placeholder"
                  dataQa="add account"
                />
              </Box>
            )}
            {R.or(!R.isEmpty(activeAccounts), !R.isEmpty(inactiveAccounts)) && (
              <Stack childGap="16px" key="account-stack">
                {!R.isEmpty(activeAccounts) && (
                  <Box
                    padding="0"
                    as="section"
                    aria-labelledby="active-accounts-section-heading"
                  >
                    <Stack childGap="0">
                      <Box padding="0">
                        <Title
                          variant="small"
                          as="h2"
                          weight={FONT_WEIGHT_SEMIBOLD}
                          id="active-accounts-section-heading"
                          dataQa="active-accounts-section-heading"
                        >
                          Active Accounts
                        </Title>
                      </Box>
                      <Box padding="0">
                        <Stack childGap="24px">{activeAccounts}</Stack>
                      </Box>
                    </Stack>
                  </Box>
                )}
                {!R.isEmpty(inactiveAccounts) && (
                  <Box
                    padding="0"
                    as="section"
                    aria-labelledby="inactive-accounts-section-heading"
                    extraStyles={`padding-top: 16px;`}
                  >
                    <Stack childGap="0">
                      <Box padding="0">
                        <Title
                          variant="small"
                          as="h2"
                          weight={FONT_WEIGHT_SEMIBOLD}
                          id="inactive-accounts-section-heading"
                          dataQa="inactive-accounts-section-heading"
                        >
                          Inactive Accounts
                        </Title>
                      </Box>
                      <Box padding="0">
                        <Stack childGap="24px">{inactiveAccounts}</Stack>
                      </Box>
                    </Stack>
                  </Box>
                )}
              </Stack>
            )}
          </Box>
        );
      default:
        if (accountLoadingExperienceEnabled) {
          return (
            <CenterStack
              content={
                <Box padding={isMobile ? "1rem" : "2rem"}>
                  <Box
                    padding={isMobile ? "1rem 0 2rem" : "1rem"}
                    extraStyles={isMobile && `svg { max-width: 100%; }`}
                  >
                    <Center intrinsic>
                      <NotFoundIcon
                        style={
                          isMobile ? { height: "158px", width: "auto" } : {}
                        }
                      />
                    </Center>
                  </Box>
                  <Heading variant="h4" textAlign="center" weight="bold">
                    Error finding your accounts
                  </Heading>
                  <Stack>
                    <Center singleChild>
                      <Box padding="0.5rem 0 0" maxWidth="37.5rem">
                        <Paragraph extraStyles="text-align: center;">
                          {`Looks like we’re currently unable to find your accounts. Please refresh the page or try again later.`}
                        </Paragraph>
                      </Box>
                    </Center>
                  </Stack>
                </Box>
              }
              contentSpacing="0"
              maxContentWidth="100%"
            />
          );
        }
        return null;
    }
  };

  return (
    <Box padding="0 0 16px" key="accounts-box">
      <Stack fullHeight childGap="24px" key="accounts-outer-stack">
        {!R.isEmpty(alertBar.alerts) && (
          <AlertBar {...alertBar} {...alertBarActions} key="alert-bar" />
        )}
        {renderObligations(obligations)}
      </Stack>
    </Box>
  );
};

export default connect(null, { push })(
  withWindowSize(React.memo(ProfileAccounts))
);
