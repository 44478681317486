import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import S from "/util/Sanctuary";
import { map, prop, pathOr, path, isEmpty } from "ramda";
import {
  getUser,
  getUserFakeObligations,
  getUserFakeObligationsForCustomerManagement,
  createObligationAssociation,
  deactivatePaymentInstrumentAttempt,
  getConstituentObligations,
  getObligationAssociations,
  getConstituentPayment,
  getConstituentPayments,
  getAccountBillURL,
  getObligationAssociation
} from "./graphql/Queries";
import { push } from "connected-react-router";
import { bindData } from "/renderer/actions";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import {
  FETCH_RESOURCES,
  fetchResourcesSuccess,
  fetchResourcesFailure,
  ASSOCIATE_OBLIGATION,
  CREATE_PAYMENT_FROM_PROFILE,
  sendProfilePayment,
  populatePaymentFromProfile,
  createPaymentScheduleSuccess,
  DEACTIVATE_PAYMENT_INSTRUMENT,
  deactivatePaymentInstrumentSuccess,
  deactivatePaymentInstrumentFailure,
  profileWalletSettingsAlertBarAction,
  NAVIGATE_TO_DETAILED_OBLIGATION,
  REFRESH_OBLIGATIONS,
  refreshObligationsSuccess,
  refreshObligationsSuccessV2,
  refreshObligationsFailure,
  accountsAlertBarAction,
  accountDetailsAlertBarAction,
  timeout,
  fetchObligations,
  fetchObligationFailure,
  fetchObligationSuccess,
  fetchObligationsSuccess,
  fetchObligationsSuccessV2,
  fetchObligationsFailure,
  FETCH_OBLIGATIONS,
  handlePaymentExpiration,
  CHECK_PAYMENT_EXPIRATION,
  associateObligationSuccess,
  associateObligationFailure,
  SET_DETAILED_OBLIGATION,
  setDetailedObligationName,
  filterFindableAccounts,
  FETCH_TRANSACTION_HISTORY,
  fetchTransactionHistorySuccess,
  fetchTransactionHistoryFailure,
  transactionHistoryAlertBarAction,
  FETCH_TRANSACTION,
  fetchTransactionSuccess,
  fetchTransactionFailure,
  fetchAccountBillURL,
  FETCH_ACCOUNT_BILL_URL,
  fetchAccountBillURLSuccess,
  fetchAccountBillURLFailure,
  FETCH_OBLIGATIONS_SUCCESS_V2,
  fetchObligation,
  FETCH_OBLIGATION
} from "./Profile.state";
import {
  getCustomerManagement,
  getAllCreditCards,
  getConstituentID
} from "./Profile.selectors";
import {
  configureEndpoint,
  isInCustomerManagement
} from "../../../../util/router-utils";
import { updateProfileResources } from "../../../checkout/pages/payment/Payment.state";
import { setLocalStorage } from "../../../../state/reducer.js";
import { util } from "@thecb/components";
import { ACCOUNT_LOADING_EXPERIENCE_FLAG } from "../../../../util/launchDarklyUtils.js";
const PROFILE_KEY = "profile";
const EXPIRING_SOON = "EXPIRING_SOON";
const EXPIRED = "EXPIRED";
const TIMEOUT_MS = 1000 * 60; // Certain requests will timeout in 1 minute..

// The fetchAccountBillURLEpic will take at least this long.
// This is to prevent the loading spinner on the account details page from flashing too quickly.
const FETCH_ACCOUNT_BILL_URL_EPIC_MINIMUM_TIME_MS = 500;

const createPaymentParams = (
  { serviceName, options, id, config, obligations },
  selectors,
  state
) => {
  const { paymentAttributes } = config;
  const accountIdAlias = paymentAttributes?.accountId ?? "";
  const accountId = obligations[0].customAttributes?.[accountIdAlias] ?? "";
  const BANK_ACCOUNT = "BANK_ACCOUNT";
  const CREDIT_CARD = "CREDIT_CARD";
  const allowedPaymentInstruments = obligations[0]
    .allowedPaymentInstruments ?? [BANK_ACCOUNT, CREDIT_CARD];
  const paymentTypes = {
    BANK_ACCOUNT: "bank",
    CREDIT_CARD: "card",
    CASH: "cash"
  };
  const allowedPaymentMethods = allowedPaymentInstruments.map(
    method => paymentTypes[method]
  );
  const lineItems = obligations.map(obligation => {
    const {
      description,
      subDescription,
      amount
    } = obligation.paymentAttributes;
    const { customAttributes: _customAttributes, amountDue } = obligation;
    const obligationData = { ..._customAttributes, amountDue };
    const paymentCustomAttributes = { ...paymentAttributes.customAttributes };
    const customAttributes = [
      ...Object.entries(paymentCustomAttributes).reduce(
        (acc, [key, value]) => [...acc, { key, value: obligationData[value] }],
        []
      ),
      { key: "line_item_description", value: description },
      { key: "line_item_sub_description", value: subDescription }
    ];
    return {
      description,
      subDescription,
      amount,
      customAttributes
    };
  });
  const customAttributeArray = lineItems[0].customAttributes;
  const subClientSlug = options?.subClientSlug;
  const accountLookupConfigKey = selectors
    .getSubclients(state)
    .find(sc => sc.path === subClientSlug)?.slug;
  return {
    lineItems,
    serviceName,
    id,
    options,
    customAttributes: customAttributeArray,
    accountId,
    allowedPaymentMethods,
    accountLookupConfigKey
  };
};

const getCommonParams = (selectors, additionalParams, state) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  ...additionalParams
});

const requestProfile = selectors => async state => {
  const accessToken = isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state);
  const userID = isInCustomerManagement
    ? state.profile.customerManagement.profileId
    : selectors.getUserID(state);
  const subClientSlugs = selectors.getSubclients(state).map(sc => sc.path);
  if (selectors.getUserFakeData(state) === true) {
    console.log(
      `%cWarning: tenant has "useFakeData" set to true. Using fake data for obligations.`,
      "background-color: rgba(51, 171, 230, 0.15); color: #27A9E1; display: inline-block; margin: 0.5rem; padding: 0.5rem; border: 1px solid #27A9E1;"
    );
    try {
      const params = getCommonParams(
        selectors,
        {
          authJwt: accessToken,
          userID,
          subClientSlugs
        },
        state
      );

      if (isInCustomerManagement) {
        const fakeDataResponse = await getUserFakeObligationsForCustomerManagement(
          params
        );
        // building this out as more fields are authorized for customer management
        return {
          ...fakeDataResponse.getUser,
          paymentInstruments: fakeDataResponse.getPaymentInstruments,
          accounts: [],
          schedules: []
        };
      }
      const fakeDataResponse = await getUserFakeObligations(params);
      return {
        ...fakeDataResponse.getUser,
        paymentInstruments: fakeDataResponse.getPaymentInstruments,
        accounts: selectors.getObligationAssociation(state),
        findableAccounts: selectors.getFindableAccounts(state) ?? [],
        filteredFindableAccounts: selectors.getFindableAccounts(state) ?? [],
        schedules: fakeDataResponse.getSchedules
      };
    } catch (e) {
      if (e.errors) {
        throw new Error(map(e.errors, prop("message")).join(" "));
      }
    }
  } else {
    try {
      const params = getCommonParams(
        selectors,
        {
          authJwt: accessToken,
          userID,
          subClientSlugs
        },
        state
      );

      const response = await getUser(params);

      return {
        ...response.getUser,
        paymentInstruments: response.getPaymentInstruments,
        accounts: selectors.getObligationAssociation(state),
        findableAccounts: selectors.getFindableAccounts(state) ?? [],
        filteredFindableAccounts: selectors.getFindableAccounts(state) ?? [],
        schedules: response.getSchedules
      };
    } catch (e) {
      if (e.errors) {
        throw new Error(map(e.errors, prop("message")).join(" "));
      }
      throw e;
    }
  }
};

const getUserDetailsFromResponse = userResponse => {
  const { email = "" } = userResponse?.authentication ?? {};
  const { firstName = "", lastName = "", constituent = {} } =
    userResponse?.person ?? {};
  const constituentID = constituent?.id ?? "";

  return {
    email,
    firstName,
    lastName,
    constituentID
  };
};

export const loadProfileEpic = selectors => (action$, state$) =>
  action$.ofType(FETCH_RESOURCES).pipe(
    RxOp.flatMap(() =>
      Rx.from(requestProfile(selectors)(state$.value)).pipe(
        RxOp.flatMap(user =>
          !isEmpty(user.schedules)
            ? Rx.of(
                fetchResourcesSuccess(user),
                createPaymentScheduleSuccess(user.schedules),
                setLocalStorage(getUserDetailsFromResponse(user)),
                fetchObligations(),
                handlePaymentExpiration()
              )
            : Rx.of(
                fetchResourcesSuccess(user),
                setLocalStorage(getUserDetailsFromResponse(user)),
                fetchObligations(),
                handlePaymentExpiration()
              )
        ),
        RxOp.catchError(err => Rx.of(fetchResourcesFailure(err)))
      )
    )
  );

const createObligationAssociationQuery = (selectors, state) => {
  const authJwt = isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state);
  const client =
    selectors.getClientSlug(state) ?? selectors.getWorkflowClient(state);
  const subClientSlug =
    selectors.getWorkflowSubClient(state) ?? selectors.getSubclientPath(state);
  const constituentID = getConstituentID(state);
  // Comes from a workflow, right now the keys for these parts reference SF HSS
  // Should change this to be more generic, will require updating FCS workflows too
  const verificationKey = `${selectors.getEmplid(state)}-${selectors.getEmplssn(
    state
  )}`.replace(/\s+/g, ""); // Remove any whitespace

  return createObligationAssociation(
    getCommonParams(
      selectors,
      { authJwt, client, subClientSlug, constituentID, verificationKey },
      state
    )
  );
};

export const associateObligationEpic = (key, selectors) => (action$, state$) =>
  action$.ofType(ASSOCIATE_OBLIGATION).pipe(
    RxOp.flatMap(() =>
      Rx.from(createObligationAssociationQuery(selectors, state$.value)).pipe(
        RxOp.flatMap(response =>
          Rx.of(
            associateObligationSuccess({
              response,
              configs: selectors.getObligationConfigs(state$.value)
            }),
            filterFindableAccounts(
              state$.value?.global?.settings?.data?.canAddObligation
            ),
            push(
              configureEndpoint(
                getCustomerManagement(state$.value[key]),
                "/profile/accounts"
              )
            )
          )
        ),
        RxOp.catchError(error => {
          console.error(`Error: ${error}`);
          return Rx.of(
            associateObligationFailure(error),
            bindData({
              location: ["notFoundErrorFlag"],
              data: true
            })
          );
        })
      )
    )
  );

const configurePaymentFixture = (action, state) => {
  const { obligations, config } = action.payload;
  const { obligationSlug } = config;
  const clientPaymentConfig = path(
    ["global", "payment", "data", obligationSlug],
    state
  );
  const paymentConfig = clientPaymentConfig
    ? clientPaymentConfig
    : pathOr({}, ["global", "payment", "data", "default"], state);

  return {
    ...paymentConfig,
    config,
    obligations
  };
};

const selectProfileResources = state => ({
  addresses: state?.profile?.settings?.resources?.value?.addresses ?? {},
  emails: state?.profile?.settings?.resources?.value?.emails ?? {},
  phoneNumbers: state?.profile?.settings?.resources?.value?.phoneNumbers ?? {}
});

export const createPaymentFromProfileEpic = selectors => (action$, state$) =>
  action$
    .ofType(CREATE_PAYMENT_FROM_PROFILE)
    .pipe(
      RxOp.flatMap(action =>
        Rx.of(configurePaymentFixture(action, state$.value)).pipe(
          RxOp.mergeMap(paymentConfiguration =>
            Rx.of(
              updateProfileResources(selectProfileResources(state$.value)),
              populatePaymentFromProfile(
                createPaymentParams(
                  paymentConfiguration,
                  selectors,
                  state$.value
                )
              ),
              sendProfilePayment(paymentConfiguration)
            )
          )
        )
      )
    );

const createDeactivatePaymentInstrumentQuery = (selectors, action, state) => {
  const authJwt = isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state);
  return deactivatePaymentInstrumentAttempt(
    getCommonParams(
      selectors,
      { authJwt, paymentInstrument: action.payload.paymentInstrument },
      state
    )
  );
};

export const deactivatePaymentInstrumentEpic = selectors => (action$, state$) =>
  action$.ofType(DEACTIVATE_PAYMENT_INSTRUMENT).pipe(
    RxOp.flatMap(action =>
      Rx.from(
        createDeactivatePaymentInstrumentQuery(selectors, action, state$.value)
      ).pipe(
        RxOp.flatMap(response =>
          Rx.of(
            profileWalletSettingsAlertBarAction(clearAlerts()),
            deactivatePaymentInstrumentSuccess(
              response.deactivatePaymentInstrument
            ),
            profileWalletSettingsAlertBarAction(
              addAlert({
                heading: "Payment Method Removed",
                text: `Your payment method ending in ${response.deactivatePaymentInstrument.lastFour} has been successfully removed from your account.`,
                variant: "success"
              })
            )
          )
        )
      )
    ),
    RxOp.catchError(error =>
      Rx.of(
        profileWalletSettingsAlertBarAction(clearAlerts()),
        deactivatePaymentInstrumentFailure(error),
        profileWalletSettingsAlertBarAction(
          addAlert({
            heading: "Payment Method Removal Error",
            text:
              "An error occurred when attempting to remove your payment method from your account. Please try again.",
            variant: "error"
          })
        )
      )
    )
  );

const getDetailedObligationName = (action, state) => {
  const itemID = action.payload.assocID;
  const { accounts = {}, properties = {} } =
    state?.profile?.settings?.resources?.value?.obligations ?? {};
  const [accountGroup] =
    Object.entries(accounts.active).find(([_, accounts]) =>
      accounts.some(account => account.id === itemID)
    ) ?? [];
  const [propertyGroup] =
    Object.entries(properties.active).find(([_, properties]) =>
      properties.some(property => property.id === itemID)
    ) ?? [];
  const clientDisplayName =
    state?.global?.clientMetadata?.data?.clientDisplayName ?? "";
  return util.general.titleCaseString(
    `${clientDisplayName} ${accountGroup || propertyGroup}`
  );
};

const createAccountBillURLQuery = (selectors, action, state) => {
  const authJwt = isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state);

  const accountId = action?.payload?.obligations?.[0]?.id;
  const subClientSlug = action?.payload?.config?.obligationSlug;

  return getAccountBillURL(
    getCommonParams(selectors, { accountId, authJwt, subClientSlug }, state)
  );
};

export const fetchAccountBillURLEpic = selectors => (action$, state$) =>
  action$.ofType(FETCH_ACCOUNT_BILL_URL).pipe(
    RxOp.flatMap(action =>
      Rx.from(
        Rx.combineLatest(
          createAccountBillURLQuery(selectors, action, state$.value)
            .then(response => {
              const {
                getAccountBillUrl: { url, ttl }
              } = response;

              const billPayload = { bill: S.RemoteData.Success({ url, ttl }) };

              return Rx.of(
                fetchAccountBillURLSuccess(billPayload),
                setLocalStorage({
                  detailedObligation: {
                    ...action.payload,
                    ...billPayload
                  }
                })
              );
            })
            .catch(error => Rx.of(fetchAccountBillURLFailure(error))),
          Rx.timer(FETCH_ACCOUNT_BILL_URL_EPIC_MINIMUM_TIME_MS)
        )
      ).pipe(RxOp.flatMap(([response, _timer]) => response))
    )
  );

export const storeDetailedObligation = (action$, state$) =>
  action$.ofType(SET_DETAILED_OBLIGATION).pipe(
    RxOp.flatMap(action => {
      const detailedName = getDetailedObligationName(action, state$.value);
      return Rx.of(
        setDetailedObligationName(detailedName),
        setLocalStorage({
          detailedObligation: { ...action.payload, name: detailedName }
        }),
        fetchAccountBillURL(action.payload)
      );
    })
  );

export const navigateToDetailedObligation = key => (action$, state$) =>
  action$
    .ofType(NAVIGATE_TO_DETAILED_OBLIGATION)
    .pipe(
      RxOp.flatMap(action =>
        Rx.of(
          push(
            configureEndpoint(
              getCustomerManagement(state$.value[key]),
              action.payload.slug
            )
          )
        )
      )
    );

const createGetConstituentObligationsQuery = (selectors, state) => {
  const authJwt = isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state);
  const client = selectors.getClientSlug(state);
  const subClientSlugs = selectors
    .getSubclients(state)
    .map(subClient => subClient.path);
  const constituentID = getConstituentID(state);

  return selectors.getUserFakeData(state)
    ? [{ constituentObligations: selectors.getFakeObligationData(state) }]
    : getConstituentObligations(
        getCommonParams(
          selectors,
          { authJwt, client, subClientSlugs, constituentID },
          state
        )
      );
};

const createGetObligationAssociationsQuery = (selectors, state) => {
  const authJwt = isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state);
  const client = selectors.getClientSlug(state);
  const subClientSlugs = selectors
    .getSubclients(state)
    .map(subClient => subClient.path);
  const constituentID = getConstituentID(state);

  return selectors.getUserFakeData(state)
    ? [{ constituentObligations: selectors.getFakeObligationData(state) }]
    : getObligationAssociations(
        getCommonParams(
          selectors,
          { authJwt, client, subClientSlugs, constituentID },
          state
        )
      );
};

export const refreshObligationsEpic = selectors => (action$, state$) =>
  action$.ofType(REFRESH_OBLIGATIONS).pipe(
    RxOp.flatMap(() => {
      const launchDarklyFlags = selectors.getLaunchDarklyFlags(state$.value);
      if (launchDarklyFlags[ACCOUNT_LOADING_EXPERIENCE_FLAG]) {
        return Rx.from(
          createGetObligationAssociationsQuery(selectors, state$.value)
        ).pipe(
          RxOp.flatMap(response =>
            Rx.of(
              accountsAlertBarAction(clearAlerts()),
              refreshObligationsSuccessV2({
                response,
                configs: selectors.getObligationConfigs(state$.value)
              }),
              filterFindableAccounts(
                state$.value?.global?.settings?.data?.canAddObligation
              )
            )
          ),
          RxOp.catchError(error =>
            Rx.of(
              accountsAlertBarAction(clearAlerts()),
              refreshObligationsFailure(error),
              accountsAlertBarAction(
                addAlert({
                  heading: "Account Refresh Error",
                  text:
                    "We were unable to refresh your amounts due. The numbers and payment status may not be up to date. Please refresh this page to try again.",
                  variant: "warn"
                })
              )
            )
          )
        );
      }
      return Rx.from(
        createGetConstituentObligationsQuery(selectors, state$.value)
      ).pipe(
        RxOp.flatMap(response =>
          Rx.of(
            accountsAlertBarAction(clearAlerts()),
            refreshObligationsSuccess({
              response,
              configs: selectors.getObligationConfigs(state$.value)
            }),
            filterFindableAccounts(
              state$.value?.global?.settings?.data?.canAddObligation
            )
          )
        ),
        RxOp.catchError(error =>
          Rx.of(
            accountsAlertBarAction(clearAlerts()),
            refreshObligationsFailure(error),
            accountsAlertBarAction(
              addAlert({
                heading: "Account Refresh Error",
                text:
                  "We were unable to refresh your amounts due. The numbers and payment status may not be up to date. Please refresh this page to try again.",
                variant: "warn"
              })
            )
          )
        )
      );
    })
  );

const createGetObligationAssociationQuery = (selectors, state, id) => {
  const authJwt = isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state);
  const client = selectors.getClientSlug(state);
  const subClientSlugs = selectors
    .getSubclients(state)
    .map(subClient => subClient.path);
  const constituentID = getConstituentID(state);
  const params = {
    id,
    ...getCommonParams(
      selectors,
      {
        authJwt,
        client,
        subClientSlugs,
        constituentID
      },
      state
    )
  };

  return selectors.getUserFakeData(state)
    ? [{ constituentObligations: selectors.getFakeObligationData(state) }]
    : getObligationAssociation(params);
};

export const fetchObligationEpic = selectors => (action$, state$) =>
  action$.ofType(FETCH_OBLIGATION).pipe(
    RxOp.mergeMap(action =>
      Rx.from(
        createGetObligationAssociationQuery(
          selectors,
          state$.value,
          action.payload?.id
        )
      )
        .pipe(
          RxOp.flatMap(response =>
            Rx.of(
              fetchObligationSuccess({
                obligation: response,
                config: action.payload.config
              })
            )
          ),
          RxOp.catchError(error =>
            Rx.of(
              fetchObligationFailure({
                id: action.payload?.id,
                error,
                config: action.payload?.config
              })
            )
          )
        )
        .pipe(
          RxOp.timeout(TIMEOUT_MS),
          RxOp.catchError(error =>
            Rx.of(
              fetchObligationFailure({
                id: action.payload?.id,
                error,
                config: action.payload?.config
              })
            )
          )
        )
    )
  );

export const fetchObligationsEpic = selectors => (action$, state$) =>
  action$.ofType(FETCH_OBLIGATIONS).pipe(
    RxOp.flatMap(() => {
      const launchDarklyFlags = selectors.getLaunchDarklyFlags(state$.value);
      if (launchDarklyFlags[ACCOUNT_LOADING_EXPERIENCE_FLAG]) {
        return Rx.from(
          createGetObligationAssociationsQuery(
            selectors,
            state$.value,
            action$.payload?.id
          )
        )
          .pipe(
            RxOp.flatMap(response =>
              Rx.of(
                accountsAlertBarAction(clearAlerts()),
                fetchObligationsSuccessV2({
                  response,
                  configs: selectors.getObligationConfigs(state$.value)
                }),
                filterFindableAccounts(
                  state$.value?.global?.settings?.data?.canAddObligation
                )
              )
            ),
            RxOp.catchError(error =>
              Rx.of(
                accountsAlertBarAction(clearAlerts()),
                fetchObligationsFailure(error)
              )
            )
          )
          .pipe(
            RxOp.timeout(TIMEOUT_MS),
            RxOp.catchError(error => Rx.of(timeout(error)))
          );
      }
      return Rx.from(
        createGetConstituentObligationsQuery(selectors, state$.value)
      )
        .pipe(
          RxOp.flatMap(response =>
            Rx.of(
              accountsAlertBarAction(clearAlerts()),
              fetchObligationsSuccess({
                response,
                configs: selectors.getObligationConfigs(state$.value)
              }),
              filterFindableAccounts(
                state$.value?.global?.settings?.data?.canAddObligation
              )
            )
          ),
          RxOp.catchError(error =>
            Rx.of(
              accountsAlertBarAction(clearAlerts()),
              fetchObligationsFailure(error),
              accountsAlertBarAction(
                addAlert({
                  heading: "Error Fetching Your Accounts",
                  text:
                    "We were unable to fetch your accounts. Please refresh this page to try again.",
                  variant: "error"
                })
              )
            )
          )
        )
        .pipe(
          RxOp.timeout(TIMEOUT_MS),
          RxOp.catchError(error => Rx.of(timeout(error)))
        );
    })
  );

export const fetchObligationsSuccessEpic = _ => (action$, _) =>
  action$.ofType(FETCH_OBLIGATIONS_SUCCESS_V2).pipe(
    RxOp.mergeMap(action => {
      const {
        response: { getObligationAssociations: obligationAssoc = [] },
        configs: obligationAssocConfig = {}
      } = action.payload.obligations;
      return Rx.from(obligationAssoc).pipe(
        RxOp.flatMap(obl =>
          Rx.of(fetchObligation({ id: obl.id, config: obligationAssocConfig }))
        )
      );
    })
  );

const createGetConstituentPaymentsQuery = (selectors, action, state) => {
  const authJwt = isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state);
  const client = selectors.getClientBrand(state);
  const agencies = selectors
    .getSubclients(state)
    .map(subClient => subClient.path);
  const constituentId = getConstituentID(state);
  const { statuses = null, page = 1, perPage = 10 } = action.payload;

  return getConstituentPayments(
    getCommonParams(
      selectors,
      {
        agencies,
        authJwt,
        client,
        constituentId,
        page: page?.toString(),
        perPage: perPage?.toString(),
        statuses
      },
      state
    )
  );
};

export const fetchTransactionHistoryEpic = selectors => (action$, state$) =>
  action$.ofType(FETCH_TRANSACTION_HISTORY).pipe(
    RxOp.flatMap(action =>
      Rx.from(
        createGetConstituentPaymentsQuery(selectors, action, state$.value)
      )
        .pipe(
          RxOp.flatMap(response =>
            Rx.of(
              fetchTransactionHistorySuccess({ response, ...action.payload })
            )
          ),
          RxOp.catchError(error =>
            Rx.of(
              fetchTransactionHistoryFailure({ error, ...action.payload }),
              transactionHistoryAlertBarAction(
                addAlert({
                  heading: "Error Fetching Your Transaction History",
                  text:
                    "We were unable to fetch your transaction history. Please refresh this page to try again.",
                  variant: "error"
                })
              )
            )
          )
        )
        .pipe(
          RxOp.timeout(TIMEOUT_MS),
          RxOp.catchError(error => Rx.of(timeout(error)))
        )
    ),
    RxOp.catchError(error => Rx.of(fetchTransactionHistoryFailure(error)))
  );

const createGetConstituentPaymentQuery = (selectors, action, state) => {
  const authJwt = isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state);
  const constituentId = getConstituentID(state);
  const { paymentId } = action.payload;

  return getConstituentPayment(
    getCommonParams(
      selectors,
      {
        authJwt,
        constituentId,
        paymentId
      },
      state
    )
  );
};

export const fetchTransactionEpic = selectors => (action$, state$) =>
  action$.ofType(FETCH_TRANSACTION).pipe(
    RxOp.flatMap(action =>
      Rx.from(createGetConstituentPaymentQuery(selectors, action, state$.value))
        .pipe(
          RxOp.flatMap(response =>
            Rx.of(fetchTransactionSuccess({ response, ...action.payload }))
          ),
          RxOp.catchError(error =>
            Rx.of(fetchTransactionFailure({ error, ...action.payload }))
          )
        )
        .pipe(
          RxOp.timeout(TIMEOUT_MS),
          RxOp.catchError(error => Rx.of(timeout(error)))
        )
    ),
    RxOp.catchError(error => Rx.of(fetchTransactionFailure(error)))
  );

const handlePaymentExpirationAlerts = creditCard => {
  const status = creditCard?.expirationStatus ?? "";
  switch (status) {
    case EXPIRING_SOON:
      return profileWalletSettingsAlertBarAction(
        addAlert({
          textOverride: `Card ending in ${creditCard.lastFour} will expire soon.`,
          height: "0px",
          padding: "0.5rem",
          variant: "warn",
          extraStyles: `margin-bottom: 0.5rem;`,
          maxContentWidth: true
        })
      );
    case EXPIRED:
      return profileWalletSettingsAlertBarAction(
        addAlert({
          textOverride: `Card ending in ${creditCard.lastFour} has expired.`,
          height: "0px",
          padding: "0.5rem",
          variant: "error",
          extraStyles: `margin-bottom: 0.5rem;`,
          maxContentWidth: true
        })
      );
    default:
      return null;
  }
};

export const checkPaymentExpirationEpic = (action$, state$) =>
  action$.ofType(CHECK_PAYMENT_EXPIRATION).pipe(
    RxOp.flatMap(() =>
      Rx.of(profileWalletSettingsAlertBarAction(clearAlerts()))
    ),
    RxOp.flatMap(() =>
      Rx.from(Object.values(getAllCreditCards(state$.value[PROFILE_KEY]))).pipe(
        RxOp.flatMap(response => Rx.of(handlePaymentExpirationAlerts(response)))
      )
    )
  );

export default loadProfileEpic;
